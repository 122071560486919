
#canvas_automata {
    border-radius: 5px;
    border: #f8f9fa 1px solid;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.logo {
    -webkit-animation: 7s rotate ease infinite;
    animation: 7s rotate ease infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.input-color {
    background-color: transparent;
    border-radius: 20%;
    height: 16px;
    width: 30px;
    border: none;
    outline: none;
    -webkit-appearance: none;
}

.input-color::-webkit-color-swatch {
    border: none;
    border-radius: 20%;
}

.input-color::-webkit-color-swatch-wrapper {
    padding: 0;
}
