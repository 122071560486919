.scroll {
    overflow-y: scroll;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: rgb(88, 88, 88);
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199);
    border-radius: 4px;
}

/* Handle on hover */
.scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(255, 255, 255);
}
